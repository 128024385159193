const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Tokenを取得する関数
const getToken = () => localStorage.getItem('token');

// APIリクエストを送信する共通関数
export const apiRequest = async (endpoint, method = 'GET', body = null) => {
  const token = getToken();
  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  if (body) {
    headers.append('Content-Type', 'application/json');
  }

  const config = {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };

  const response = await fetch(`${BASE_URL}/${endpoint}`, config);

  // エラーハンドリング
  if (!response.ok) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const error = await response.json();
      throw new Error(error.message || 'APIエラーが発生しました。');
    } else {
      throw new Error('APIエラーが発生しました。');
    }
  }

  const data = await response.json();
  return data;
};

export const login = async (email, password) => {
  const data = await apiRequest('login', 'POST', { email, password });
  return data;
};

export const listQueue = async () => {
  return await apiRequest('pdfqueue/list', 'GET');
};

export const generate = async (file) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('file', file); // 'file' はバックエンドの期待するフィールド名

  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
    // 'Content-Type' ヘッダーは multipart/form-data の場合、自動的に設定されるため、明示的に追加しない
  }

  const config = {
    method: 'POST',
    headers,
    body: formData, // FormData オブジェクトをそのまま設定
  };

  const response = await fetch(`${BASE_URL}/pdfqueue/add`, config); // エンドポイントを修正

  // エラーハンドリング
  if (!response.ok) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const error = await response.json();
      throw new Error(error.message || 'APIエラーが発生しました。');
    } else {
      throw new Error('APIエラーが発生しました。');
    }
  }

  const data = await response.json();
  return data;
};

export const downloadCsv = async (queueId) => {
  const url = `${BASE_URL}/pdfqueue/download/${queueId}`;

  const token = getToken();
  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  
  const response = await fetch(url, {
    method: 'GET',
    headers,
  });

  if (response.ok) {
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'download.csv'; // デフォルトファイル名

    if (contentDisposition) {
      // RFC 5987形式のエンコードされたファイル名を解析
      const filenameMatch = contentDisposition.match(/filename\*=[^'']+''(.+)/);
      if (filenameMatch) {
        // パーセントエンコーディングされたファイル名をデコード
        filename = decodeURIComponent(filenameMatch[1]);
      } else {
        // 単純なfilename="filename.csv"形式の場合のフォールバック
        const simpleMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (simpleMatch) {
          filename = simpleMatch[1];
        }
      }
    }

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } else {
    throw new Error('Failed to download CSV.');
  }
};

export const addCategory = async (categoryName) => {
  const data = await apiRequest('category/add', 'POST', { category_name: categoryName });
  return data;
};

export const fetchCategories = async () => {
  return await apiRequest('category/list', 'GET');
};

export const uploadCsv = async (categoryId, file) => {
    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const headers = new Headers();
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
        // 注意: 'Content-Type' ヘッダーはmultipart/form-dataで自動的に設定されるため、手動で追加しない
    }

    const config = {
        method: 'POST',
        headers,
        body: formData,
    };

    const response = await fetch(`${BASE_URL}/category/${categoryId}/upload_csv`, config);

    // エラーハンドリング
    if (!response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            const error = await response.json();
            throw new Error(error.message || 'ファイルのアップロード中にエラーが発生しました。');
        } else {
            throw new Error('ファイルのアップロード中にエラーが発生しました。');
        }
    }

    const data = await response.json();
    return data;
};

export const listFilesInCategory = async (categoryId) => {
  const data = await apiRequest(`category/${categoryId}/list`, 'GET');
  return data;
};

export const deleteCategory = async (categoryId) => {
  return await apiRequest(`category/${categoryId}/delete`, 'DELETE');
};


export const getBasicQuestions = async (query, categoryId) => {
    const data = await apiRequest('chat/basic_questions', 'POST', { query: query, category_id: categoryId });
    return data;
};

export const chatQuery = async (query, history, categoryId, mainInformation, additionalInformation) => {
    const formattedHistory = history.map((msg, index) => ({
        index: index.toString(),
        role: msg.role,
        text: msg.text
    }));

    const data = await apiRequest('chat/query', 'POST', { 
        query: query, 
        history: formattedHistory, 
        category_id: categoryId,
        main_information: mainInformation,
        additional_information: additionalInformation 
    });

    return data;
};
