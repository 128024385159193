// Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';

function Layout({ children }) {
  const location = useLocation();

  const noSidebarPaths = ['/chat'];

  return (
    <div className="app">
      {!noSidebarPaths.includes(location.pathname) && <Sidebar />}
      <div className="content">
        {children}
      </div>
    </div>
  );
}

export default Layout;

