import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { fetchCategories, addCategory, listFilesInCategory, uploadCsv, deleteCategory } from '../utils/apiRequest';
import { Modal, Button } from 'react-bootstrap'; // react-bootstrap の Modal をインポート

function formatTimestamp(timestamp) {
    if (!timestamp) return '---';
    const date = new Date(timestamp);

    return new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).format(date).replace(/\//g, '-');
}

function VectorDB() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');

    const fetchCat = async () => {
        try {
            const data = await fetchCategories();
            setCategories(data.categories || []);
            if (data.categories.length > 0) {
                setSelectedCategory(data.categories[0].id);
            } else {
                setError('No categories available. Please add a category.');
            }
        } catch (err) {
            console.error("Failed to fetch categories:", err);
            setError('Failed to load categories.');
        }
    };

    useEffect(() => {
        fetchCat();
    }, []);

    useEffect(() => {
        const fetchFiles = async () => {
            if (!selectedCategory) return;
            try {
                const files = await listFilesInCategory(selectedCategory);
                setCategoryData(files || []);
            } catch (err) {
                console.error("Failed to fetch files:", err);
                setError('Failed to fetch files.');
            }
        };
        fetchFiles();
    }, [selectedCategory]);

    const handleCategorySelect = (e) => setSelectedCategory(e.target.value);

    const handleAddCategory = async () => {
        if (!newCategoryName) {
            setError('Category name cannot be empty.');
            return;
        }
        try {
            await addCategory(newCategoryName);
            setNewCategoryName('');
            setShowNewCategoryModal(false);
            setError('');
            await fetchCat();
        } catch (err) {
            console.error("Failed to add category:", err);
            setError('Failed to add category.');
        }
    };

    const handleDeleteCategory = async () => {
        try {
            await deleteCategory(selectedCategory);
            setError('');
            await fetchCat();
        } catch (err) {
            console.error("Failed to delete category:", err);
            setError('Failed to delete category.');
        }
    };

    const handleCsvUpload = async (files) => {
        if (!files.length) {
            setError('No file selected.');
            return;
        }
        setUploading(true);
        try {
            await uploadCsv(selectedCategory, files[0]);
            const data = await listFilesInCategory(selectedCategory);
            setCategoryData(data || []);
            setError('');
        } catch (err) {
            console.error("Failed to upload CSV:", err);
            setError('Failed to upload file.');
        } finally {
            setUploading(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleCsvUpload,
        multiple: false,
        accept: 'text/csv'
    });

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <h1>Vector DB Manager</h1>
                <div {...getRootProps({ className: 'dropzone p-5 mb-3 border-dashed border-2 border-primary rounded-lg text-center' })}>
                    <input {...getInputProps()} />
                    <p className="text-primary">
                        CSVファイルをここにドラッグ&ドロップ、<br />またはクリックしてファイルを選択してください。
                    </p>
                </div>
                
                <div className="d-flex align-items-center">
                    <select 
                        className="form-control mr-2" 
                        value={selectedCategory} 
                        onChange={handleCategorySelect}
                        style={{ width: '50%' }} // ドロップダウンの幅を50%に設定
                    >
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                    <button className="btn btn-danger mr-2" onClick={handleDeleteCategory}>
                        削除
                    </button>
                    <button className="btn btn-success" onClick={() => setShowNewCategoryModal(true)}>
                        新規作成
                    </button>
                </div>

                {/* 新規カテゴリ作成用のモーダル */}
                <Modal show={showNewCategoryModal} onHide={() => setShowNewCategoryModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>新規カテゴリ作成</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Enter category name" 
                            value={newCategoryName} 
                            onChange={(e) => setNewCategoryName(e.target.value)} 
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowNewCategoryModal(false)}>
                            キャンセル
                        </Button>
                        <Button variant="primary" onClick={handleAddCategory}>
                            作成
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <section className="content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">カテゴリー内ファイルリスト</h3>
                    </div>
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>ファイル名</th>
                                    <th>QA数</th>
                                    <th>登録日</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryData.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.file_name}</td>
                                        <td>{item.qa_count}</td>
                                        <td>{formatTimestamp(item.registration_date)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {uploading && <p className="text-center">Uploading...</p>}
            </section>
        </div>
    );
}

export default VectorDB;

