import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { listQueue, generate, downloadCsv } from '../utils/apiRequest';


function formatTimestamp(timestamp) {
    if (!timestamp) return '---';
    const date = new Date(timestamp);

    return new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // 24時間表記
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // ローカルタイムゾーン
    }).format(date).replace(/\//g, '-'); // '/' を '-' に置換
}

function Generator() {
  const [queueItems, setQueueItems] = useState([]);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const fetchQueueItems = async () => {
    setError('');
    try {
      const data = await listQueue();
      setQueueItems(data);
    } catch (error) {
      setError('キュー情報の取得に失敗しました。');
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchQueueItems();
    const intervalId = setInterval(fetchQueueItems, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const onDrop = async (acceptedFiles) => {
    setError('');
    setIsUploading(true);
    try {
      await generate(acceptedFiles[0]);
      await fetchQueueItems();
    } catch (error) {
      setError('ファイルのアップロードに失敗しました。');
      console.error(error.message);
    }
    setIsUploading(false);
  };

  const onDownload = async (queueId) => {
    try {
      await downloadCsv(queueId);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>QA Generator</h1>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        {isUploading && <div>アップロード中...</div>}
        <div {...getRootProps({ className: 'dropzone p-5 mb-3 border-dashed border-2 border-primary rounded-lg text-center' })}>
          <input {...getInputProps()} />
          <p className="text-primary">
            PDFファイルをここにドラッグ&ドロップ、<br />またはクリックしてファイルを選択してください。
          </p>
        </div>
      </section>
      <section className="content">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">キューのリスト</h3>
          </div>
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th>識別番号</th>
                  <th>ファイル名</th>
                  <th>ページ数</th>
                  <th>QA数</th>
                  <th>登録時刻</th>
                  <th>完了時刻</th>
                  <th>ダウンロード</th>
                </tr>
              </thead>
              <tbody>
                {queueItems.map((item) => (
                  <tr key={item.queue_id}>
                    <td>{item.md5hash.substring(0, 6)}</td>
                    <td>{item.original_filename}</td>
                    <td>{item.page_count}</td>
                    <td>{item.qa_count}</td>
                    <td>{formatTimestamp(item.registered_timestamp)}</td>
                    <td>{formatTimestamp(item.completed_timestamp) || '---'}</td>
		    <td>
			  {item.completed_timestamp ? (
			    <button className="btn btn-success btn-sm" onClick={() => onDownload(item.queue_id)}>
			      <i className="fas fa-download"></i> DL
			    </button>
			  ) : '---'}
		    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Generator;

