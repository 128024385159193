// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import LoginPage from './components/LoginPage';
import Generator from './pages/Generator';
import VectorDB from './pages/VectorDB';
import ChatbotPage from './pages/ChatbotPage';
import { PrivateRoute } from './PrivateRoute';
import { useAuth } from './AuthContext'; 

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/generator"
            element={
              <PrivateRoute>
                <Generator />
              </PrivateRoute>
            }
          />
          <Route
            path="/vectordb"
            element={
              <PrivateRoute>
                <VectorDB />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <ChatbotPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;

