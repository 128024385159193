import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // AuthContextからuseAuthをインポート

function Sidebar() {
    const { logout } = useAuth(); // useAuthフックを使用してlogout関数を取得
    const navigate = useNavigate(); // ナビゲーション用のフック

    const handleLogout = () => {
        logout(); // logout関数を呼び出してログアウト処理
        navigate('/login'); // ログアウト後にログインページへリダイレクト
    };

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/" className="brand-link">
                <span className="brand-text font-weight-light">QA Generator Admin</span>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add items here */}
                        <li className="nav-item">
                            <Link to="/generator" className="nav-link">
                                <i className="nav-icon fas fa-th"></i>
                                <p>Generator</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/vectordb" className="nav-link">
                                <i className="nav-icon fas fa-edit"></i>
                                <p>VectorDB manager</p>
                            </Link>
                        </li>
	                <li className="nav-item">
                            <Link to="/chat" className="nav-link">
                                <i className="nav-icon fas fa-comments"></i>
                                <p>Chat Mode</p>
                            </Link>
                        </li>
                        {/* ログアウトボタン */}
                        <li className="nav-item">
                            <button onClick={handleLogout} className="nav-link btn">
                                <i className="nav-icon fas fa-sign-out-alt"></i>
                                <p>ログアウト</p>
                            </button>
                        </li>
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
    );
}

export default Sidebar;

