import React, { createContext, useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode'; // 修正: 名前付きインポートに変更
import { apiRequest } from './utils/apiRequest';

const AuthContext = createContext({
  user: null,
  loading: true,
  login: () => {},
  logout: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log(token);
    if (token) {
      try {
        const decoded = jwtDecode(token); // トークンのデコード
	console.log(decoded);
        // トークンの有効期限が切れていないか確認
        if (decoded.exp * 1000 > Date.now()) {
          setUser(decoded);
        } else {
          localStorage.removeItem('token'); // 有効期限切れの場合はトークンを削除
        }
      } catch (error) {
        console.log("Token decoding failed:", error);
        localStorage.removeItem('token'); // デコード失敗時もトークンを削除
      }
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const data = await apiRequest('login', 'POST', { email, password });
      console.log(data);
      if (data.access_token) {
	localStorage.setItem('token', data.access_token);
	const decoded = jwtDecode(data.access_token);
	setUser(decoded);
      } else {
	throw new Error('Token is null'); // エラーをスローして処理を中断
      }
      return data;
    } catch (error) {
      throw error; // エラーハンドリングを呼び出し元に委ねる
    }
  };

  const logout = () => {
    localStorage.removeItem('token'); // トークンの削除
    setUser(null); // ユーザー状態をnullにリセット
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

